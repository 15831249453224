import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { collection, doc, addDoc, deleteDoc, getCountFromServer, query, where, getDocs } from 'firebase/firestore'; 
import makeCancellablePromise from 'make-cancellable-promise';
import axios from 'axios';
import { NumericFormat } from 'react-number-format';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  LineShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from 'react-share';
import { RiCreativeCommonsLine, RiCreativeCommonsByLine, RiCreativeCommonsNcLine, RiCreativeCommonsSaLine } from 'react-icons/ri';
import { FaEnvelope, FaFacebook, FaLinkedin, FaLine, FaPinterest, FaReddit, FaTelegram, FaSquareTumblr, FaSquareXTwitter, FaWhatsapp } from 'react-icons/fa6';
import { SiWorkplace } from 'react-icons/si';
import figlet from 'figlet';
import standard from 'figlet/importable-fonts/Standard.js'

import { db } from './../helper/firebase';
import Video from './../assets/vid/video.mp4';
import { ReactComponent as Logo } from './../assets/img/garuda-pancasila.svg';

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      title: '',
      ip: '',
      check: true,
      reg: '',
      count: ''
    }

    this.copy = 2024;
    this.today = new Date();
    this.title = 'PERINGATAN DARURAT';
    this.url = 'https://peringatandarurat.id';
    this.hashtag = 'PERINGATANDARURAT';
    this.hashtags = [this.hashtag, 'KawalPutusanMK'];
    this.desc = 'Kalau kalian melihat poster berwarna biru dengan tulisan peringatan darurat, ini memang darurat. Disebut darurat karena memang baru sekarang putusan MK langsung direspons DPR dengan membuat undang-undang yang dikebut dalam satu hari saja. Sekali lagi, satu hari.';

    this.cancellableCount = '';
    this.cancellableIp = '';
    this.cancellableAdd = '';
    this.cancellableDelete = '';

    this.actCampaign = this.actCampaign.bind(this);
  }

  componentDidMount(){
    this.cancellableCount = makeCancellablePromise(
      getCountFromServer(query(collection(db, 'petisi'), where('petisiStatus', '==', true)))
    );

    this.cancellableCount.promise.then((snapshot) => {
      this.setState({ count: snapshot.data().count });
    }).catch((error) => {
      console.error(error);
    });

    this.interval = setInterval(() => {
      let theTitle = this.state.title;

      if(this.state.title !== ' '){
        theTitle = ' ';
      }else{
        theTitle = this.title;
      }
      
      this.setState({ title: theTitle })
    }, 1000);

    this.cancellableIp = makeCancellablePromise(
      axios.get('https://api.ipify.org?format=jsonp&callback=')
    );

    this.cancellableIp.promise
    .then(response => response.data)
    .then((result) => {
      this.setState({ ip: result.replace('({"ip":"', '').replace('"});', '') });
    }).catch((error) => {
      console.error(error);
    });
    
    figlet.parseFont('Standard', standard);
    figlet('Peringatan Darurat', {
      font: 'Standard',
      // horizontalLayout: 'fitted',
      // verticalLayout: 'default',
      // width: 280,
      // whitespaceBreak: true,
      // kerning: 'fitted'
    }, (err, data) => {
      if (err) {
        console.log('Something went wrong...');
        console.dir(err);
        return;
      }
      
      console.log(data);
      console.log('%cKALAU KALIAN MELIHAT POSTER BERWARNA BIRU DENGAN TULISAN PERINGATAN DARURAT, INI MEMANG DARURAT.', 'color: white;background-color: #00006D;');
      console.log('%cDISEBUT DARURAT KARENA MEMANG BARU SEKARANG PUTUSAN MK LANGSUNG DIRESPONS DPR DENGAN MEMBUAT UNDANG-UNDANG YANG DIKEBUT DALAM SATU HARI SAJA.', 'color: white;background-color: #00006D;');
      console.log('%cSEKALI LAGI, SATU HARI.', 'color: white;background-color: #00006D;');
      console.log('%c20 AGUSTUS 2024', 'color: yellow;background-color: #00006D;');
      console.log('%cHARI SELASA, MAHKAMAH KONSTITUSI MEMUTUSKAN MENGUBAH SYARAT AMBANG BATAS PENCALONAN KEPALA DAERAH.', 'color: white;background-color: #00006D;');
      console.log('%cPARTAI ATAU GABUNGA PARTAI POLITIK TAK LAGI HARUS MENGUMPULKAN MINIMAL 20% KURSI DI DPRD ATAU 25% SUARA SAH UNTUK MENCALONKAN KEPALA DAERAH DAN WAKIL KEPALA DAERAH.', 'color: white;background-color: #00006D;');
      console.log('%cMK JUGA MEMUTUSKAN CALON KEPALA DAERAH TINGKAT PROVINSI ATAU CALON GUBERNUR BERUSIA 30 TAHUN SAAT DITETAPKAN OLEH KPU SEBAGAI KANDIDAT RESMI.', 'color: white;background-color: #00006D;');
      console.log('%cPUTUSAN MK INI CUKUP PROGRESIF KARENA AGAK MENJAUH DARI BUDAYA KEKUASAAN KITA YANG HOBI MENYODORKAN KANDIDAT YANG SANGAT SEDIKIT HASIL HOMPIMPAH PARA ELIT.', 'color: white;background-color: #00006D;');
      console.log('%cSETIDAKNYA MEMUNGKINKAN LEBIH BANYAK ORANG DAN LEBIH BANYAK PARTAI UNTUK MAJU DALAM PILKADA.', 'color: white;background-color: #00006D;');
      console.log('%cTAPI, SEMUANYA BATAL.', 'color: white;background-color: #00006D;');
      console.log('%cBUBAR.', 'color: white;background-color: #00006D;');
      console.log('%c21 AGUSTUS 2024', 'color: yellow;background-color: #00006D;');
      console.log('%cBESOKNYA, DPR MEMUTUSKAN AMBANG BATAS PARTAI UNTUK MENCALONKAN PASANGAN DIKEMBALIKAN KE ATURAN LAMA.', 'color: white;background-color: #00006D;');
      console.log('%cSEDANGKAN USIA KANDIDAT DIPUTUSKAN MENGIKUTI KEPUTUSAN MAHKAMAH AGUNG YAITU BERUSIA 30 TAHUN SAAT DILANTIK.', 'color: white;background-color: #00006D;');
      console.log('%cSEHINGGA MEMUNGKINKAN KAESANG YANG SUDAH DICALONKAN SEJUMLAH PARPOL BISA MAJU DALAM KONTESTASI.', 'color: white;background-color: #00006D;');
      console.log('%cNIATNYA JUGA SUDAH TIDAK BAIK SEJAK AWAL.', 'color: white;background-color: #00006D;');
      console.log('%cDPR MAU MENYIASATI PUTUSAN MK YANG SUDAH SANGAT JELAS, MENGIKAT DAN FINAL, BERLAKU UNTUK SEMUANYA.', 'color: white;background-color: #00006D;');
      console.log('%cBIKIN UNDANG-UNDANG DALAM SEHARI MUSTAHIL ADA NASKAH AKADEMIKNYA, TIDAK MUNGKIN ADA SOSIALISASI RANCANGANNYA DULU, APALAGI MENDENGARKAN ASPIRASI DAN PARTISIPASI KITA SEBAGAI RAKYAT.', 'color: white;background-color: #00006D;');
      console.log('%cINI BUKAN TENTANG ANIES, AHOK, KAESANG, ATAU PDI PERJUANGAN.', 'color: white;background-color: #00006D;');
      console.log('%cMULA-MULA INI TENTANG BAGAIMANA PROSES BERNEGARA DILAKSANAKAN DENGAN POTONG KOMPAS SEHINGGA MELECEHKAN NALAR WAJAR KITA SEBAGAI WARGA NEGARA.', 'color: white;background-color: #00006D;');
      console.log('%cTEMAN-TEMAN PERINGATAN DARURAT INI PERLU KITA SEBARKAN.', 'color: white;background-color: #00006D;');
      console.log('%cPERTAMA KARENA KITA MARAH, DAN BERHAK UNTUK ITU.', 'color: white;background-color: #00006D;');
      console.log('%cKEDUA, SUPAYA SEBANYAK-BANYAKNYA ORANG TAHU APA YANG TERJADI DI GEDUNG DPR BUKAN UNTUK KEPENTINGAN RAKYAT.', 'color: white;background-color: #00006D;');
      console.log('%cPRESIDEN DAN DPR HARUS MENGHENTIKAN SEGALA UPAYA MENENTANG PUTUSAN MAHKAMAH KONSTITUSI.', 'color: white;background-color: #00006D;');
      console.log('%cJIKA DPR DAN PEMERINTAH MAU MEREVISI TANPA BERPATOKAN PADA PUTUSAN MK, INI RENTAN DIANGGAP PEMBANGKANGAN KONSTITUSI.', 'color: white;background-color: #00006D;');
      console.log('%cDAN SAYA CEMAS PEMBANGKANGAN KONSTITUSI INI BISA BERUJUNG DENGAN PEMBANGKANGAN SIPIL.', 'color: white;background-color: #00006D;');
      console.log('%c- CATATAN NAJWA', 'color: white;background-color: #00006D;');
      console.log('https://www.instagram.com/p/C-9rx4DPFGT/');
      console.log('');
      console.log('%cSILAKAN UNTUK MEMBUKA, MEMBAGIKAN, MENYEBARLUASKAN, MENGGUNAKAN, MENAMPILKAN DI TEMPAT UMUM, DSB. PANJANG UMUR PERJUANGAN.', 'color: white;background-color: #00006D;');
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.ip !== prevState.ip){
      if(this.state.ip){
        this.cancellableCheck = makeCancellablePromise(
          getDocs(query(collection(db, 'petisi'), where('petisiIp', '==', this.state.ip)))
        );
    
        this.cancellableCheck.promise.then((snapshot) => {
          if(snapshot.size === 1){
            this.setState({
              reg: snapshot.docs[0].id,
              check: false,
            });
          }
        }).catch((error) => {
          console.error(error);

          this.setState({ check: false });
        });
      }
    }
  }

  componentWillUnmount(){
    clearInterval(this.interval);
    
    if(this.cancellableCount){
      this.cancellableCount.cancel();
    }

    if(this.cancellableIp){
      this.cancellableIp.cancel();
    }
    
    if(this.cancellableAdd){
      this.cancellableAdd.cancel();
    }
    
    if(this.cancellableDelete){
      this.cancellableDelete.cancel();
    }
  }

  actCampaign(event, ip){
    if(this.state.reg === ''){
      this.cancellableAdd = makeCancellablePromise(
        addDoc(collection(db, 'petisi'), {
          petisiIp: this.state.ip,
          petisiUa: window.navigator.userAgent,
          petisiTime: new Date(),
          petisiStatus: true
        })
      );

      this.cancellableAdd.promise.then((response) => {
        this.setState({
          reg: response.id,
          count: this.state.count + 1
        });
      }).catch((error) => {
        console.error(error);
      });
    }else{
      this.cancellableDelete = makeCancellablePromise(
        deleteDoc(doc(db, 'petisi', this.state.reg))
      );

      this.cancellableDelete.promise.then((response) => {
        this.setState({
          reg: '',
          count: this.state.count - 1
        });
      }).catch((error) => {
        console.error(error);
      });
    }
  }

  render(){
    return (
      <>
        <Helmet>
          <title>{this.state.title}</title>
        </Helmet>
        
        <section className="hero">
          <div>
            <h1 data-content={this.title}>
              {this.title}
            </h1>
            
            <div className="logo">
              <div>
                <Logo />
                <Logo />
                <Logo />
              </div>
            </div>

            <h2 data-content="RI-00">
              RI-00
            </h2>
          </div>

          <div className="grad"></div>

          <video allowFullScreen={false} muted="muted" autoPlay="autoplay" playsInline="playsinline" loop="loop">
            <source src={Video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </section>

        {this.state.count ? (
          <section className="count">
            <div>
              <div>
                Sejumlah
                <NumericFormat displayType="text" thousandSeparator="." decimalSeparator="," value={this.state.count} />
                Alamat IP
              </div><br />
              Sudah marah dan mengetahui apa yang terjadi di gedung DPR.
            </div>
          </section>
        ) : ''}

        <section className="content">
          <p>Kalau kalian melihat poster berwarna biru dengan tulisan peringatan darurat, ini memang darurat.</p>
          <p>Disebut darurat karena memang baru sekarang putusan MK langsung direspons DPR dengan membuat undang-undang yang dikebut dalam satu hari saja.</p>
          <p>Sekali lagi, satu hari.</p>
          <p><strong className="yellow">20 Agustus 2024</strong><br />Hari selasa, mahkamah konstitusi memutuskan mengubah syarat ambang batas pencalonan kepala daerah.</p>
          <p>Partai atau gabunga partai politik tak lagi harus mengumpulkan minimal 20% kursi di DPRD atau 25% suara sah untuk mencalonkan kepala daerah dan wakil kepala daerah.</p>
          <p>MK juga memutuskan calon kepala daerah tingkat provinsi atau calon gubernur berusia 30 tahun saat ditetapkan oleh KPU sebagai kandidat resmi.</p>
          <p>Putusan MK ini cukup progresif karena agak menjauh dari budaya kekuasaan kita yang hobi menyodorkan kandidat yang sangat sedikit hasil hompimpah para elit.</p>
          <p>Setidaknya memungkinkan lebih banyak orang dan lebih banyak partai untuk maju dalam PILKADA.</p>
          <p>Tapi, semuanya batal.</p>
          <p><strong>Bubar</strong>.</p>
          <p><strong className="yellow">21 Agustus 2024</strong><br />Besoknya, DPR memutuskan ambang batas partai untuk mencalonkan pasangan dikembalikan ke aturan lama.</p>
          <p>Sedangkan usia kandidat diputuskan mengikuti keputusan mahkamah agung yaitu berusia 30 tahun saat dilantik.</p>
          <p>Sehingga memungkinkan Kaesang yang sudah dicalonkan sejumlah parpol bisa maju dalam kontestasi.</p>
          <p>Niatnya juga sudah tidak baik sejak awal.</p>
          <p>DPR mau menyiasati putusan MK yang sudah sangat jelas, mengikat dan final, berlaku untuk semuanya.</p>
          <p>Bikin undang-undang dalam sehari mustahil ada naskah akademiknya, tidak mungkin ada sosialisasi rancangannya dulu, apalagi mendengarkan aspirasi dan partisipasi kita sebagai rakyat.</p>
          <p>Ini bukan tentang Anies, Ahok, Kaesang, atau PDI Perjuangan.</p>
          <p>Mula-mula ini tentang bagaimana proses bernegara dilaksanakan dengan potong kompas sehingga melecehkan nalar wajar kita sebagai warga negara.</p>
          <p className="cenblink" data-content="Teman-teman peringatan darurat ini perlu kita sebarkan.">Teman-teman peringatan darurat ini perlu kita sebarkan.</p>
          <p>Pertama karena kita marah, dan berhak untuk itu.</p>
          <p>Kedua, supaya sebanyak-banyaknya orang tahu apa yang terjadi di gedung DPR bukan untuk kepentingan rakyat.</p>
          <p>Presiden dan DPR harus menghentikan segala upaya menentang putusan mahkamah konstitusi.</p>
          <p>Jika DPR dan pemerintah mau merevisi tanpa berpatokan pada putusan MK, ini rentan dianggap <strong>pembangkangan konstitusi</strong>.</p>
          <p>Dan saya cemas pembangkangan konstitusi ini bisa berujung dengan <strong>pembangkangan sipil</strong>.</p>
          <p>- <a target="_blank" rel="noreferrer" href="https://www.instagram.com/p/C-9rx4DPFGT/">Catatan Najwa</a></p>
          <p></p>
        </section>

        {!this.state.check ? (
          <>
            {this.state.reg ? (
              <section className="congrats">
                <div>
                  <p>Terima kasih telah marah dan tahu tentang peringatan darurat ini.</p>
                  <button type="button" className="astext" onClick={event => this.actCampaign(event, this.state.ip)}>Klik disini apabila saya tidak marah dan menutup mata, telinga serta hati nurani atas apa yang terjadi di gedung DPR.</button>
                </div>
              </section>
            ) : (
              <section className="cta">
                <p>Dengan mengklik tombol dibawah ini,<br />Saya dengan IP <strong>{this.state.ip}</strong> menyatakan dengan sadar bahwa:</p>
                <button onClick={event => this.actCampaign(event, this.state.ip)} type="button">Saya marah dan tahu apa yang terjadi di gedung DPR.</button>
              </section>
            )}
          </>
        ) : ''}
        
        <footer>
          <div className="note">Silakan untuk membuka, membagikan, menyebarluaskan, menggunakan, menampilkan di tempat umum, dsb. Panjang umur perjuangan.</div>
          <ul className="list-share">
            <li>
              <EmailShareButton url={this.url} subject={this.title} body={this.desc}>
                <FaEnvelope />
              </EmailShareButton>
            </li>
            <li>
              <FacebookShareButton url={this.url} hashtag={this.hashtag}>
                <FaFacebook />
              </FacebookShareButton>
            </li>
            <li>
              <LinkedinShareButton url={this.url} title={this.title} summary={this.desc}>
                <FaLinkedin />
              </LinkedinShareButton>
            </li>
            <li>
              <LineShareButton url={this.url} title={this.title}>
                <FaLine />
              </LineShareButton>
            </li>
            <li>
              <PinterestShareButton url={this.url} media="" description={this.desc}>
                <FaPinterest />
              </PinterestShareButton>
            </li>
            <li>
              <RedditShareButton url={this.url} title={this.title}>
                <FaReddit />
              </RedditShareButton>
            </li>
            <li>
              <TelegramShareButton url={this.url} title={this.title}>
                <FaTelegram />
              </TelegramShareButton>
            </li>
            <li>
              <TumblrShareButton url={this.url} title={this.title} tags={this.hashtags} caption={this.desc}>
                <FaSquareTumblr />
              </TumblrShareButton>
            </li>
            <li>
              <TwitterShareButton url={this.url} title={this.title} hashtags={this.hashtags} related={['@PeringatanRI_00']}>
                <FaSquareXTwitter />
              </TwitterShareButton>
            </li>
            <li>
              <WhatsappShareButton url={this.url} title={this.title}>
                <FaWhatsapp />
              </WhatsappShareButton>
            </li>
            <li>
              <WorkplaceShareButton url={this.url} quote={this.title} hashtag={this.hashtag}>
                <SiWorkplace />
              </WorkplaceShareButton>
            </li>
          </ul>
          <ul className="list-foot">
            <li>
              <span>Donasi Terbuka</span>
              <ul>
                <li>
                  <strong>Dompet Front Dapur Umum</strong>
                  <span>(Bandung dan sekitarnya)</span>
                  BCA 2780694486 a/n Muhammad Rizki Fauzan
                </li>
                <li>
                  <strong>Aksi Semarang</strong>
                  <span>(Semarang dan sekitarnya)</span>
                  BTN 010890161004847 a/n Amadela Andra Dynala
                </li>
              </ul>
            </li>
            <li>
              <span>Hotline</span>
              <ul>
                <li>
                  <strong>Bantuan Hukum</strong>
                  <span>(Bandung dan sekitarnya)</span>
                  <a target="_blank" rel="noreferrer" href="tel:+6282258843986">+6282258843986</a>
                </li>
              </ul>
            </li>
            <li>
              <span>Media</span>
              <ul>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://x.com/PeringatanRI_00">
                    X
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://narasi.tv/">
                    Narasi TV
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <span>Sumber Daya</span>
              <ul>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.dropbox.com/scl/fo/hxr31uv0fu51bkyk1038s/AK6Brb5vay1pw8q3i1zkVL0?dl=0&e=1&rlkey=xlrzrivzty9bca6sag7kuwvxd&st=g2vg4kqm">
                    Video Loop
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://t.co/i50BRqoyeY">
                    Kaos &  Tas Jinjing
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="list-foot">
            <li>
              <span>#PeringatanDarurat</span>
              <ul>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://x.com/hashtag/PeringatanDarurat">
                    X
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/explore/tags/peringatandarurat/">
                    Instagram
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/hashtag/peringatandarurat/">
                    Facebook
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <span>#KawalPutusanMK</span>
              <ul>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://x.com/hashtag/KawalPutusanMK">
                    X
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/explore/tags/kawalputusanmk/">
                    Instagram
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/hashtag/kawalputusanmk/">
                    Facebook
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <span>#DemokrasiDiHabisi</span>
              <ul>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://x.com/hashtag/DemokrasiDiHabisi">
                    X
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/explore/tags/demokrasidihabisi/">
                    Instagram
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/hashtag/demokrasidihabisi/">
                    Facebook
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <span>#LawanPolitikDinasti</span>
              <ul>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://x.com/hashtag/LawanPolitikDinasti">
                    X
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/explore/tags/lawanpolitikdinasti/">
                    Instagram
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/hashtag/lawanpolitikdinasti/">
                    Facebook
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <div className="copy">
            <a href="/">PeringatanDarurat</a> &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} oleh raykat Indonesia. Dipublikasikan dengan lisensi <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">CreativeCommons BY-NC-SA 4.0 <RiCreativeCommonsLine /> <RiCreativeCommonsByLine /> <RiCreativeCommonsNcLine /> <RiCreativeCommonsSaLine /></a>.
          </div>
        </footer>
      </>
    );
  }
}

export default App;
