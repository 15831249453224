import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// import { getAuth } from 'firebase/auth';
// import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseApp = initializeApp({
    apiKey: "AIzaSyC7y50gQoMxoy9Grg8OZzx04TL6-P8NNiI",
    authDomain: "peringatan-darurat.firebaseapp.com",
    projectId: "peringatan-darurat",
    storageBucket: "peringatan-darurat.appspot.com",
    messagingSenderId: "1011672568521",
    appId: "1:1011672568521:web:71c9a079a47db7293f3318",
    measurementId: "G-R48W71HZJE"
});

getAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);
// export const auth = getAuth(firebaseApp);
// export const storage = getStorage(firebaseApp);

export default firebaseApp;